<template>
  <div
    class="missed-call-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="!hover" class="missed-call-info missed-call-block">
      <div class="missed-call-avatar">
        <VFlag
          v-if="item % 3 === 1"
          flag="US"
          class="phone-number-flag margin-right-2"
        />
        <VFlag
          v-else-if="item % 3 === 2"
          flag="AU"
          class="phone-number-flag margin-right-2"
        />
        <VFlag v-else flag="AM" class="phone-number-flag margin-right-2" />
        <img
          v-if="item % 3 === 1"
          src="https://variety.com/wp-content/uploads/2023/06/avatar-1.jpg?w=1000"
          class="missed-call-avatar-img"
        />
        <Avatar v-else username="Karen Mar" class="missed-call-sender-name" />
      </div>
      <div class="missed-call-data">
        <div class="missed-call-user-name">
          <div class="fs-default fw-medium">
            <span>
              {{ call.name }}
            </span>
            <span class="missed-call-badge" />
          </div>
          <div class="fw-medium fs-mini">2 mins ago</div>
        </div>
        <div class="fs-default missed-call-status">Missed Call</div>
      </div>
    </div>
    <div v-else class="missed-call-actions missed-call-block">
      <div class="missed-call-action-events">
        <div class="missed-call-action-event call">
          <fluent-icon
            icon="missed-call"
            size="18"
            view-box="0 0 18 18"
            fill-color="#ffffff"
          />
          <span class="margin-left-2 fw-medium">Call Again</span>
        </div>
        <div class="missed-call-action-event go-to">
          <fluent-icon
            icon="call-widget-right-arrow"
            size="16"
            view-box="0 0 16 16"
            fill-color="#ffffff"
          />
        </div>
      </div>
      <div class="missed-call-action-event close">
        <fluent-icon icon="dismiss" size="16" fill-color="#404040" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VFlag from 'dashboard/components/ui/VFlag';
import Avatar from 'dashboard/components/widgets/Avatar';

export default {
  components: { Avatar, VFlag },
  props: {
    call: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
};
</script>
<style lang="scss" scoped>
.missed-call-block {
  padding: var(--space-normal);
  margin: var(--space-smaller);
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow-medium);
  background-color: var(--white);
}
.missed-call-info {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 80px;
  .missed-call-data {
    padding-left: var(--space-small);
    flex-grow: 1;
  }
  .missed-call-user-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .missed-call-status {
    color: #fb7a89;
  }
  .missed-call-avatar {
    display: flex;
    align-items: center;
    position: relative;
    .missed-call-avatar-img {
      width: 48px;
      height: 48px;
      border-radius: var(--border-radius-rounded);
    }
    .missed-call-sender-name {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }
  }
  .phone-number-flag {
    height: 18px;
    width: 18px;
    overflow: hidden;
    border-radius: var(--border-radius-rounded);
    position: absolute;
    left: 25px;
    top: -3px;
  }
  .missed-call-badge {
    background: #fb7a89;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: var(--space-smaller);
  }
}
.missed-call-actions {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .missed-call-action-events {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-grow: 1;
  }
  .missed-call-action-event {
    border-radius: var(--border-radius-semirounded);
    padding: var(--space-slab);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: var(--font-size-normal);
    margin-right: var(--space-small);
    cursor: pointer;
  }
  .call {
    background-color: var(--g-350);
    color: var(--white);
  }
  .go-to {
    background-color: var(--v-400);
  }
  .close {
    background-color: var(--s-50);
  }
}
</style>
