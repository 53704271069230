<template>
  <div class="missed-calls-panel-body">
    <div v-on-clickaway="closeMissedCallsPanel">
      <div class="content-wrap">
        <MissedCallItem
          v-for="missedCall in missedCalls"
          :key="missedCall.id"
          :call="missedCall"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MissedCallItem from './MissedCallItem';
import stickyBannerMixin from 'dashboard/mixins/stickyBannerMixin';
import { mixin as clickaway } from 'vue-clickaway';
import callMixin from 'dashboard/mixins/call';

export default {
  components: { MissedCallItem },
  mixins: [clickaway, stickyBannerMixin, callMixin],
  methods: {
    closeMissedCallsPanel() {
      this.setMissedCallsVisibility(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.missed-calls-panel-body {
  flex-direction: column;
  max-height: 90vh;
  height: fit-content;
  width: 37rem;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  right: var(--space-small);
  top: var(--space-jumbo);
  z-index: var(--z-index-twenty);
  border-radius: var(--border-radius-large);
  background-color: var(--s-50);
  overflow-y: auto;
}
</style>
