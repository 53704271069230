<template>
  <li
    :class="{
      'tabs-title': true,
      'is-active': active,
    }"
    :data-testid="dataTestId"
  >
    <a @click="onTabClick">
      {{ name }}
      <!-- <span v-if="showBadge" class="badge">
        {{ getItemCount }}
      </span> -->
    </a>
  </li>
</template>
<script>
import { replaceToDashLowerCaseE2E } from '../../../helper/commons';

export default {
  name: 'WootTabsItem',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
    testId: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [String, Number],
      default: 0,
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    active() {
      return this.index === this.$parent.index;
    },
    getItemCount() {
      return this.count;
    },
    dataTestId() {
      return `tabs-${replaceToDashLowerCaseE2E(this.testId.toString()) ||
        replaceToDashLowerCaseE2E(this.name)}`;
    },
  },
  methods: {
    onTabClick(event) {
      event.preventDefault();
      if (!this.disabled) {
        this.$parent.$emit('change', this.index);
      }
    },
  },
};
</script>
