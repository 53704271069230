<template>
  <div
    class="container"
    :style="{ top: y + 'px', left: x + 'px' }"
    @mousedown="startDrag"
  >
    <button id="startup-button" @click="startupClientXVUE()">
      Receive Call
    </button>
    <section id="info" class="left-column">
      <div v-if="clientNameXVUE" id="client-name">
        Your client name: {{ clientNameXVUE }}
      </div>
      <div v-if="audioSelectionDivVisible" id="output-selection">
        <label>Ringtone Devices</label>
        <select id="ringtone-devices" multiple>
          <option
            v-for="deviceItem in ringtoneDevicesXVUE"
            :key="`${deviceItem.id}-ringtone-devices`"
            :value="deviceItem.id"
            :selected="deviceItem.active"
          >
            {{ deviceItem.label }}
          </option>
        </select>
        <label>Speaker Devices</label>
        <select id="speaker-devices" multiple>
          <option
            v-for="deviceItem in speakerDevicesXVUE"
            :key="`${deviceItem.id}-speaker-devices`"
            :value="deviceItem.id"
            :selected="deviceItem.active"
          >
            {{ deviceItem.label }}
          </option>
        </select>
      </div>
    </section>
    <section class="center-column">
      <div v-if="callControlsDivVisible" id="call-controls">
        <form>
          <label for="agent-phone-number">From Phone Number:</label>
          <input
            id="agent-phone-number"
            type="text"
            :value="agentPhoneNumberInputXVUE"
          />

          <label for="phone-number">To Phone Number</label>
          <input id="phone-number" type="text" :value="phoneNumberInputXVUE" />
          <button
            id="button-call"
            type="submit"
            :disabled="callButtonDisabledXVUE"
            @click.prevent="makeOutgoingCallXVUE"
          >
            Call
          </button>
        </form>
        <button
          v-if="outgoingCallHangupButtonVisible"
          id="button-hangup-outgoing"
          @click="outgoingCallHangupXVUE"
        >
          Hang Up
        </button>
        <div v-if="incomingCallDivVisible" id="incoming-call">
          <p class="instructions">
            Incoming Call from
            <span id="incoming-number" v-html="incomingPhoneNumberElHTML" />
          </p>
          <button
            v-if="incomingCallAcceptButtonVisible"
            id="button-accept-incoming"
            @click="acceptIncomingCallXVUE"
          >
            Accept
          </button>
          <button
            v-if="incomingCallRejectButtonVisible"
            id="button-reject-incoming"
            @click="rejectIncomingCallXVUE"
          >
            Reject
          </button>
          <button
            v-if="incomingCallHangupButtonVisible"
            id="button-hangup-incoming"
            class="hide"
            @click="hangupIncomingCallXVUE"
          >
            Hangup
          </button>
        </div>
        <div
          v-show="volumeIndicatorsVisible"
          id="volume-indicators"
          class="hide"
        >
          <label>Mic Volume</label>
          <div
            id="input-volume"
            :style="{
              width: inputVolumeBarWidthXVUE,
              backgroundColor: inputVolumeBarColorXVUE,
            }"
          />
          <label>Speaker Volume</label>
          <div
            id="output-volume"
            :style="{
              width: outputVolumeBarWidthXVUE,
              backgroundColor: outputVolumeBarColorXVUE,
            }"
          />
        </div>
      </div>
    </section>
    <section class="right-column">
      <div
        v-show="showLogXVUE"
        id="log"
        ref="scrollContainer"
        class="scrollable-view "
      >
        <p v-for="(logXVUE, index) in logsXVUE" :key="index" class="log-entry">
          {{ logXVUE }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { Device } from '@twilio/voice-sdk';
import callApi from '../../api/call';

export default {
  data() {
    return {
      x: 100, // Initial x position
      y: 100, // Initial y position
      isDragging: false,
      offsetX: 0,
      offsetY: 0,
      tokenXVUE: null,
      deviceXVUE: null,
      callIncomingXVUE: null,
      callOutgoingXVUE: null,
      clientNameXVUE: null,
      incomingPhoneNumberElHTML: null,
      speakerDevicesXVUE: [],
      ringtoneDevicesXVUE: [],
      logsXVUE: [],
      showLogXVUE: false,
      callControlsDivVisible: false,
      incomingCallDivVisible: false,
      incomingCallAcceptButtonVisible: false,
      incomingCallRejectButtonVisible: false,
      incomingCallHangupButtonVisible: false,
      audioSelectionDivVisible: false,
      outgoingCallHangupButtonVisible: false,
      volumeIndicatorsVisible: false,
      phoneNumberInputXVUE: '+15105008701',
      agentPhoneNumberInputXVUE: '+19383002505',
      callButtonDisabledXVUE: false,
      inputVolumeBarWidthXVUE: null,
      inputVolumeBarColorXVUE: null,
      outputVolumeBarWidthXVUE: null,
      outputVolumeBarColorXVUE: null,
    };
  },
  mounted() {
    // speakerDevices.addEventListener('change', updateOutputDevice);
    // ringtoneDevices.addEventListener('change', updateRingtoneDevice);
    // function updateOutputDevice() {
    //   console.log();
    //   const selectedDevices = Array.from(speakerDevices.children)
    //     .filter(node => node.selected)
    //     .map(node => node.getAttribute('data-id'));
    //   device.audio.speakerDevices.set(selectedDevices);
    // }
    // function updateRingtoneDevice() {
    //   const selectedDevices = Array.from(ringtoneDevices.children)
    //     .filter(node => node.selected)
    //     .map(node => node.getAttribute('data-id'));
    //   device.audio.ringtoneDevices.set(selectedDevices);
    // }
  },
  methods: {
    log(message) {
      this.logsXVUE.push(message);
      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollContainer;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      });
    },
    async startupClientXVUE() {
      this.log('Requesting Access Token...');
      const response = await callApi.requestToken();
      this.tokenXVUE = response.data.token;
      this.clientNameXVUE = response.data.identity;
      this.intitializeDeviceXVUE();
    },
    intitializeDeviceXVUE() {
      this.showLogXVUE = true;
      this.log('Initializing device');
      this.deviceXVUE = new Device(this.tokenXVUE, {
        logLevel: 1,
        codecPreferences: ['opus', 'pcmu'],
      });
      this.addDeviceListenersXVUE();
      this.deviceXVUE.register();
    },
    addDeviceListenersXVUE() {
      this.deviceXVUE.on('registered', () => {
        this.log('Twilio.Device Ready to make and receive calls!');
        this.callControlsDivVisible = true;
      });
      this.deviceXVUE.on('error', error => {
        this.log('Twilio.Device Error: ' + error.message);
      });
      this.deviceXVUE.on('incoming', this.handleIncomingCallXVUE);
      this.deviceXVUE.audio.on('deviceChange', this.updateAllAudioDevicesXVUE);
      if (this.deviceXVUE.audio.isOutputSelectionSupported) {
        this.audioSelectionDivVisible = true;
      }

      return true;
    },
    handleIncomingCallXVUE(call) {
      this.callIncomingXVUE = call;
      this.log(`Incoming call from ${this.callIncomingXVUE.parameters.From}`);
      this.incomingCallDivVisible = true;
      this.incomingPhoneNumberElHTML = this.callIncomingXVUE.parameters.From;
      this.callIncomingXVUE.on(
        'cancel',
        this.handleDisconnectedIncomingCallXVUE
      );
      this.callIncomingXVUE.on(
        'disconnect',
        this.handleDisconnectedIncomingCallXVUE
      );
      this.callIncomingXVUE.on(
        'reject',
        this.handleDisconnectedIncomingCallXVUE
      );
    },
    acceptIncomingCallXVUE() {
      this.callIncomingXVUE.accept();
      this.log('Accepted incoming call.');
      this.incomingCallAcceptButtonVisible = false;
      this.incomingCallRejectButtonVisible = false;
      this.incomingCallHangupButtonVisible = true;
    },
    rejectIncomingCallXVUE() {
      this.callIncomingXVUE.reject();
      this.log('Rejected incoming call');
      this.resetIncomingCallUIXVUE();
    },
    hangupIncomingCallXVUE() {
      this.callIncomingXVUE.disconnect();
      this.log('Hanging up incoming call');
      this.resetIncomingCallUIXVUE();
    },
    resetIncomingCallUIXVUE() {
      this.incomingPhoneNumberElHTML = null;
      this.incomingCallAcceptButtonVisible = true;
      this.incomingCallRejectButtonVisible = true;
      this.incomingCallHangupButtonVisible = false;
      this.incomingCallDivVisible = false;
    },
    handleDisconnectedIncomingCallXVUE() {
      this.log('Incoming call ended.');
      this.resetIncomingCallUIXVUE();
    },
    updateAllAudioDevicesXVUE() {
      if (this.deviceXVUE) {
        this.updateDevicesXVUE(
          this.speakerDevicesXVUE,
          this.deviceXVUE.audio.speakerDevices.get()
        );
        this.updateDevicesXVUE(
          this.ringtoneDevicesXVUE,
          this.deviceXVUE.audio.ringtoneDevices.get()
        );
      }
    },
    updateDevicesXVUE(selectOptions, selectedDevices) {
      this.deviceXVUE.audio.availableOutputDevices.forEach(
        (availableDevice, id) => {
          const exists = selectOptions.some(option => option.id === id);
          if (!exists) {
            let isActive = selectedDevices.size === 0 && id === 'default';
            selectedDevices.forEach(deviceItem => {
              if (deviceItem.deviceId === id) {
                isActive = true;
              }
            });
            selectOptions.push({
              id,
              label: availableDevice.label,
              active: isActive,
            });
          }
        }
      );
    },
    async makeOutgoingCallXVUE() {
      let params = {
        To: this.phoneNumberInputXVUE,
        Agent: this.agentPhoneNumberInputXVUE,
      };

      if (this.deviceXVUE) {
        this.log(`Attempting to call ${params.To} ...`);

        const call = await this.deviceXVUE.connect({ params });
        this.callOutgoingXVUE = call;
        this.callOutgoingXVUE.on(
          'accept',
          this.updateUIAcceptedOutgoingCallXVUE
        );
        this.callOutgoingXVUE.on(
          'disconnect',
          this.updateUIDisconnectedOutgoingCallXVUE
        );
        this.callOutgoingXVUE.on(
          'cancel',
          this.updateUIDisconnectedOutgoingCallXVUE
        );
        this.callOutgoingXVUE.on('error', error => {
          this.log('An error has occurred: ' + error);
        });
      } else {
        this.log('Unable to make call.');
      }
    },

    outgoingCallHangupXVUE() {
      this.log('Hanging up ...');
      this.callOutgoingXVUE.disconnect();
    },

    updateUIAcceptedOutgoingCallXVUE() {
      this.log('Call in progress ...');
      this.callButtonDisabledXVUE = true;
      this.outgoingCallHangupButtonVisible = true;
      this.volumeIndicatorsVisible = true;
      this.bindVolumeIndicatorsXVUE();
      // bindVolumeIndicators(call); //todo
    },

    updateUIDisconnectedOutgoingCallXVUE() {
      this.log('Call disconnected.');
      this.callButtonDisabledXVUE = false;
      this.outgoingCallHangupButtonVisible = false;
      this.volumeIndicatorsVisible = false;
      // volumeIndicators.classList.add('hide');
    },

    bindVolumeIndicatorsXVUE() {
      this.callOutgoingXVUE.on('volume', (inputVolume, outputVolume) => {
        let inputColor = 'red';
        if (inputVolume < 0.5) {
          inputColor = 'green';
        } else if (inputVolume < 0.75) {
          inputColor = 'yellow';
        }

        this.inputVolumeBarWidthXVUE = Math.floor(inputVolume * 300) + 'px';
        this.inputVolumeBarColorXVUE = inputColor;

        let outputColor = 'red';
        if (outputVolume < 0.5) {
          outputColor = 'green';
        } else if (outputVolume < 0.75) {
          outputColor = 'yellow';
        }

        this.outputVolumeBarWidthXVUE = Math.floor(outputVolume * 300) + 'px';
        this.outputVolumeBarColorXVUE = outputColor;
      });
    },

    startDrag(event) {
      if (event.target.closest('.content')) {
        return;
      }
      this.isDragging = true;
      this.offsetX = event.clientX - this.x;
      this.offsetY = event.clientY - this.y;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onMouseMove(event) {
      if (this.isDragging) {
        this.x = event.clientX - this.offsetX;
        this.y = event.clientY - this.offsetY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.stopDrag);
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: 400px;
  height: fit-content;
  background-color: palevioletred;
  cursor: grab;
  margin: 3px;
  padding: 10px;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container:active {
  cursor: grabbing;
}

.scrollable-view {
  overflow-y: auto;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  background-color: #f9f9f9;
}

button {
  margin: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#output-volume,
#input-volume {
  height: 15px;
}
</style>
