import { render, staticRenderFns } from "./MissedCallItem.vue?vue&type=template&id=e9d3f78a&scoped=true&"
import script from "./MissedCallItem.vue?vue&type=script&lang=js&"
export * from "./MissedCallItem.vue?vue&type=script&lang=js&"
import style0 from "./MissedCallItem.vue?vue&type=style&index=0&id=e9d3f78a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9d3f78a",
  null
  
)

export default component.exports