<template>
  <main class="wizard-body small-9 columns">
    <form
      class="call-based-body full-height"
      @submit.prevent="execSetNewInboxData"
    >
      <div>
        <page-header
          class="channel-type-header"
          :header-title="$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.TITLE')"
          :header-content="$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.DESCRIPTION')"
        />
        <section>
          <div class="channel-price">
            <div class="channel-price-section">
              <p>
                <span class="web-base-icon">
                  ☎️
                </span>
                <span class="fs-default fw-normal">
                  {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.TITLE') }}
                </span>
              </p>
              <span v-if="isBillingDataLoaded" class="fs-default fw-normal">
                {{
                  isMonthly
                    ? $t('PRICING_PLANS.PLAN.MONTHLY')
                    : $t('PRICING_PLANS.PLAN.YEARLY')
                }}
                <fluent-icon
                  v-tooltip="subTypeTooltip"
                  icon="information"
                  size="16"
                  view-box="0 0 20 20"
                />
              </span>
            </div>
            <h6 class="margin-top-1 margin-bottom-1 channel-price-section">
              {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.SUBSCRIPTIONS') }}
            </h6>
            <div class="channel-price-section margin-bottom-1">
              <div>
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.CHANNEL') }}
              </div>
              <div v-if="isBillingDataLoaded">
                {{ formattedPrice(callBaseChannelPrice) }} /
                {{ isMonthlyText }}
              </div>
            </div>
            <div class="channel-price-section margin-bottom-1">
              <div>
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.NUMBER') }}
              </div>
              <div v-if="isBillingDataLoaded">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.STARTING_FROM') }}
                {{ formattedPrice(minCurrentPrice * 100) }} /
                {{ isMonthlyText }}
              </div>
            </div>
            <h6 class="margin-top-1 margin-bottom-1 channel-price-section">
              {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.USAGE_BASED') }}
            </h6>
            <div class="channel-price-section margin-bottom-1">
              <div class="text-capitalize">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_MINUTE') }}
              </div>
              <div v-if="isBillingDataLoaded">
                {{
                  $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.CALCULATED_LATER')
                }}
              </div>
            </div>
            <div class="channel-price-section">
              <div class="text-capitalize">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_SMS') }}
              </div>
              <div v-if="isBillingDataLoaded">
                {{
                  $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.CALCULATED_LATER')
                }}
              </div>
            </div>
          </div>
        </section>
        <section class="channel-details">
          <div class="medium-12 columns">
            <label :class="{ error: $v.channelName.$error }">
              <fluent-icon
                v-tooltip="
                  `${$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.TOOLTIP')}`
                "
                icon="info"
                size="16"
              />
              <span class="fs-snug">{{
                $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.LABEL')
              }}</span>
              <input
                v-model.trim="channelName"
                type="text"
                :placeholder="
                  $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.PLACEHOLDER')
                "
                @blur="$v.channelName.$touch"
              />
              <span v-if="$v.channelName.$error" class="message">{{
                $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHANNEL_NAME.ERROR')
              }}</span>
            </label>
          </div>
          <!-- TODO: Karen: Hide for now -->
          <!-- <div class="medium-12 columns">
            <label
              :class="{
                error: $v.channelUrl.$error,
                'channel-url-input': true,
              }"
            >
              <inbox-slug-input
                ref="InboxSlugInput"
                :value="channelUrl"
                channel-id="0"
                :show-copy-btn="false"
                @input="
                  channelUrl = $event;
                  isChannelWebsiteChanged = true;
                "
                @ok="slugError = false"
                @error="slugError = true"
                @blur="$v.channelUrl.$touch"
              />
              <span v-if="$v.channelUrl.$error" class="message">{{
                $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHANNEL_NAME.ERROR')
              }}</span>
            </label>
          </div> -->
        </section>
      </div>
      <div>
        <woot-submit-button button-text="Continue" class="next-btn" />
      </div>
    </form>
    <section class="call-based-body">
      <woot-modal
        :show.sync="showCallBasedPopup"
        :on-close="hideCallBasedPopup"
      >
        <BusinessNumberConfirm :on-close="hideCallBasedPopup" />
      </woot-modal>
    </section>
  </main>
</template>
<script>
import BusinessNumberConfirm from './BusinessNumberConfirm';
import inboxMixin from 'shared/mixins/inboxMixin';
import PageHeader from '../../../SettingsSubPageHeader';
import accountMixin from 'dashboard/mixins/account';
import billingMixin from 'dashboard/mixins/billing';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';

export default {
  components: {
    PageHeader,
    BusinessNumberConfirm,
  },
  mixins: [inboxMixin, accountMixin, billingMixin],
  data() {
    return {
      channelName: null,
      channelUrl: null,
      isChannelWebsiteChanged: false,
      slugError: true,
    };
  },
  computed: {
    minCurrentPrice() {
      return Math.min(
        ...this.countryBasePricing.numberPrices.prices.map(
          price => price.currentPrice
        )
      );
    },
    subTypeTooltip() {
      return this.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.TYPE', {
        type: this.isMonthly
          ? this.$t('PRICING_PLANS.PLAN.MONTHLY')
          : this.$t('PRICING_PLANS.PLAN.YEARLY'),
      });
    },
  },
  watch: {
    // channelName() {
    //   if (!this.isChannelWebsiteChanged) {
    //     this.slugError = false;
    //     this.channelUrl = this.createSlug(this.channelName);
    //     this.$refs.InboxSlugInput.checkSlug(this.channelUrl);
    //   }
    // },
  },
  validations: {
    channelName: {
      required,
      maxLength: maxLength(100),
      minLength: minLength(3),
    },
    // channelUrl: {
    //   required,
    // },
  },
  created() {
    this.channelName = this.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.CHANNEL');
    this.setNewInboxType('call');
    this.fetchCountryBasePricing({
      accountId: this.accountId,
      countryCode: 'US',
    });
  },
  methods: {
    createSlug(value) {
      return value
        .toString()
        .normalize('NFKD')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    },
    execSetNewInboxData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const data = {
        name: this.channelName,
        greeting_enabled: true,
        greeting_message: this.getDefaultGreeting,
        channel: {
          type: 'call',
          website_url: '',
          webhook_url: '',
        },
      };
      this.setNewInboxData(data);
      this.openCallBasedPopup();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/customMixins';

.channel-price {
  margin-top: var(--space-two);
  width: 70%;
  @include gray-block;
  flex-direction: column;
  align-items: start;
  .channel-price-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: var(--font-size-small);
  }
}
.web-base-icon {
  font-size: var(--font-size-default);
}
.channel-details {
  width: 60%;
  margin-top: var(--space-semilarge);
}
.label-with-tooltip {
  display: flex;
  align-items: center;
  span {
    padding-right: var(--space-small);
  }
}
</style>
