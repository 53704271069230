<template>
  <div class="column container content-box" :style="dynamicStyle">
    <div class="header">
      <div class="description">
        {{ this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.SUB_TITLE') }}
      </div>
    </div>
    <div class="h-separator" />
    <div
      ref="datesDiv"
      class="dates-holder"
      @scroll="handleScrollDates"
      @mousedown="startDrag"
      @mousemove="handleDrag"
      @mouseup="stopDrag"
      @mouseleave="stopDrag"
    >
      <div
        style="position: sticky; left: 0; background-color: #ffffff; z-index: 1000; padding-right: 1.25rem; border-right: 1px solid #f0f0f0;"
      >
        <date-picker
          v-model="selectedDate"
          placeholder="YYYY-MM-DD"
          :disabled-date="disableDates"
        />
      </div>
      <div class="times-range">
        <div class="times-list">
          <div v-for="time in getTimesList" :key="time" class="time-item">
            <span>{{ time }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="h-separator" />
    <div
      ref="agentsData"
      class="agents-data"
      @scroll="handleScrollDiagrams"
      @mousedown="startDrag"
      @mousemove="handleDrag"
      @mouseup="stopDrag"
      @mouseleave="stopDrag"
    >
      <woot-loading-state
        v-if="agentsActivity.isFetching"
        message="Loading ..."
      />
      <div
        v-for="agent in getAgentsList"
        v-else
        :key="agent.id"
        class="agent-data-holder"
      >
        <div class="agent-avatar">
          <div class="avatar">
            <thumbnail
              :src="agent.thumbnail"
              :status="agent.availability_status"
              :username="agent.name"
              size="40px"
            />
          </div>
          <div class="data">
            <div class="name">
              {{ agent.name }}
            </div>
            <div class="email">
              {{
                agent.email.length > 16
                  ? agent.email.substring(0, 16) + '...'
                  : agent.email
              }}
            </div>
          </div>
        </div>
        <div class="agent-activity">
          <agent-activity-diagram
            :data="
              agentsActivity.data.find(element => element.user_id === agent.id)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { mapGetters } from 'vuex';
import AgentActivityDiagram from './components/AgentActivityDiagram.vue';
import maxWidthMixin from '../../../../../shared/mixins/maxWidthMixin';

export default {
  components: {
    AgentActivityDiagram,
    DatePicker,
    Thumbnail,
  },
  mixins: [maxWidthMixin],
  data() {
    return {
      selectedDate: new Date(),
      fetchingActivity: false,
      isDragging: false,
      startX: 0,
      startScrollLeft: 0,
      dynamicStyle: {},
    };
  },
  computed: {
    ...mapGetters({
      agentsActivity: 'getAgentsActivity',
    }),
    getAgentsList() {
      return this.$store.getters['agents/getAgents'] || [];
    },
    getTimesList() {
      return [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ];
    },
  },
  watch: {
    selectedDate() {
      this.fetchActivityData();
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.fetchActivityData();
    this.subtractValue = 370;
  },
  methods: {
    handleScrollDates(event) {
      const agentsDataDiv = this.$refs.agentsData;
      agentsDataDiv.scrollLeft = event.target.scrollLeft;
    },
    handleScrollDiagrams(event) {
      const datesDiv = this.$refs.datesDiv;
      datesDiv.scrollLeft = event.target.scrollLeft;
    },
    fetchActivityData() {
      this.fetchingActivity = true;
      try {
        const datePayload = `${this.selectedDate.getFullYear()}-${(
          this.selectedDate.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}-${this.selectedDate
          .getDate()
          .toString()
          .padStart(2, '0')}`;
        this.$store.dispatch('fetchAgentsActivity', {
          date: datePayload,
          timezone: this.getTimeZone(),
        });
        this.fetchingActivity = false;
      } catch {
        this.showAlert('Error getting agents activity status.');
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startScrollLeft = this.$refs.datesDiv.scrollLeft;
    },
    handleDrag(event) {
      if (!this.isDragging) return;

      const deltaX = event.clientX - this.startX;

      this.$refs.datesDiv.scrollLeft = this.startScrollLeft - deltaX;
    },
    stopDrag() {
      this.isDragging = false;
    },
    disableDates(date) {
      const disabledDate = new Date('2024-02-13');
      return date < disabledDate;
    },
    getTimeZone() {
      const offset = new Date().getTimezoneOffset();
      const sign = offset > 0 ? '-' : '+'; // Note: getTimezoneOffset() returns the difference in minutes from UTC, negative for ahead of UTC
      const absOffset = Math.abs(offset);
      const hours = Math.floor(absOffset / 60);
      const minutes = absOffset % 60;
      return `UTC${sign}${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mx-datepicker input {
    height: 3.3rem;
    margin: 0 0 1rem !important;
  }
}
.container {
  display: flex;
  flex-direction: column;
  user-select: none;
  .mx-datepicker {
    margin-top: 10px;
  }
  .header {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 700;
      font-size: 1.8rem;
    }
    .description {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
  .v-separator {
    border-right: 0.063rem solid #f0f0f0;
    height: 70%;
    margin-left: 1.25rem;
    margin-bottom: 1.6rem;
  }
  .h-separator {
    border-bottom: 0.063rem solid #f0f0f0;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    max-width: 1540px;
  }
  .dates-holder::-webkit-scrollbar {
    display: none;
  }
  .dates-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.25rem;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .times-range {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.6rem;
      margin-top: 0.625rem;
      .times-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.313rem 0;
        margin: 0 20px;
        .time-item {
          font-weight: 500;
          font-size: 12px;
          color: #35373b;
          border-left: 1px solid #cbcbcb;
          user-select: none;
          width: 70px;
          position: relative;
          padding-top: 10px;
          span {
            position: absolute;
            bottom: -16px;
            left: -16px;
          }
          &:last-child {
            border-right: 1px solid #cbcbcb;
          }
          &:after {
            position: absolute;
            content: '';
            width: calc(100% - 6px);
            height: 4px;
            left: 6px;
            top: 0;
            background: repeating-linear-gradient(
              to right,
              #dedede,
              #dedede 1px,
              transparent 1px,
              transparent 7px
            );
          }
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 7px;
            left: 34px;
            top: 0;
            background: #dedede;
          }
        }
      }
    }
  }
  .agents-data {
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow: scroll;
    width: 100%;
    height: 100%;
    .agent-data-holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      border-bottom: 1px solid #f0f0f0;
      width: 1540px;
      .v-separator {
        margin-bottom: 0;
      }
      .agent-avatar {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 225px;
        position: sticky;
        left: 0;
        background-color: #ffffff;
        z-index: 1000;
        border-right: 1px solid #f0f0f0;
        .data {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .name {
            font-weight: 400;
            font-size: 14px;
            color: #232323;
          }
          .email {
            font-weight: 400;
            font-size: 14px;
            color: #787887;
          }
        }
      }
    }
  }
}
</style>
