const state = {
  audioRecords: [],
  activeRecord: null,
  playPause: 'pause',
  nextPrev: null,
  seekData: 0,
  rateData: 1,
  bannerVisibility: false,
  missedCallsVisibility: false,
};

export const getters = {
  getAudioRecords: $state => $state.audioRecords,
  getActiveRecord: $state => $state.activeRecord,
  getPlayPause: $state => $state.playPause,
  getNextPrev: $state => $state.nextPrev,
  getSeekData: $state => $state.seekData,
  getRateData: $state => $state.rateData,
  getBannerVisibility: $state => $state.bannerVisibility,
  getMissedCallsVisibility: $state => $state.missedCallsVisibility,
};

export const mutations = {
  setAudioRecords($state, records) {
    $state.audioRecords = records;
  },
  setActiveRecord($state, record) {
    $state.activeRecord = record;
  },
  setSeekData($state, data) {
    $state.seekData = data;
  },
  setRateData($state, data) {
    $state.rateData = data;
  },
  setPlayPause($state, data) {
    $state.playPause = data;
  },
  setNextPrev($state, data) {
    $state.nextPrev = data;
  },
  setBannerVisibility($state, visible) {
    $state.bannerVisibility = visible;
  },
  setMissedCallsVisibility($state, visible) {
    $state.missedCallsVisibility = visible;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
