<template>
  <div>
    <div
      v-if="!isCallWidgetShown && isSmallWidgetShown"
      :class="{
        'call-widget-btn': true,
      }"
      :style="`bottom: ${bottom}px; right: ${right}px`"
    >
      <div class="call-widget-icon-wrapper" @click.prevent="toggleChat">
        <fluent-icon
          icon="call-widget-open"
          size="36"
          view-box="0 0 36 36"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
          fill-color="none"
          class="cursor-pointer"
        />
      </div>
      <div v-if="answered" class="call-widget-duration">
        {{ formatDuration(callDuration) }}
      </div>
      <div class="call-widget-img">
        <img v-if="userInfo.imageUrl" :src="userInfo.imageUrl" />
        <div
          v-else
          class="call-widget-caller-name"
          :style="{ backgroundColor: generatedColor }"
        >
          {{ userInitial(userInfo.name) }}
        </div>
      </div>
      <div
        class="call-widget-icon-wrapper"
        :style="`cursor: ${cursor};`"
        @mousedown="mouseDown"
      >
        <fluent-icon
          size="24"
          view-box="0 0 24 24"
          icon="call-widget-grab"
          class="call-widget-grab"
        />
      </div>
      <div
        class="call-widget-background-image"
        :style="{
          backgroundImage: `linear-gradient(${defaultColor}, ${defaultColor}), ${
            userInfo.imageUrl
              ? `url(${userInfo.imageUrl})`
              : `linear-gradient(${generatedColor}, ${generatedColor})`
          }`,
        }"
      />
    </div>
    <div
      v-if="!isCallWidgetHidden"
      class="call-widget-box"
      :class="{
        'call-show-box': isCallWidgetShown,
        'call-hide-box': isCallWidgetHiding,
      }"
      :style="
        isCallWidgetHiding
          ? { bottom: `${bottom + 32}px`, right: `${right}px` }
          : {}
      "
    >
      <div class="call-widget-content">
        <div class="call-widget-content-icon" @click.prevent="toggleChat">
          <fluent-icon
            icon="call-widget-close"
            size="36"
            view-box="0 0 36 36"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
            fill-color="none"
          />
        </div>
        <div class="call-widget-content-avatar">
          <img
            v-if="userInfo.imageUrl"
            class="call-widget-img"
            :src="userInfo.imageUrl"
          />
          <div
            v-else
            class="call-widget-caller-name"
            :style="{ backgroundColor: generatedColor }"
          >
            {{ userInitial(userInfo.name) }}
          </div>
        </div>
        <div class="call-widget-content-data">
          <p class="call-widget-content-number margin-bottom-0">
            {{ userInfo.phoneNumber }}
          </p>
          <p class="call-widget-content-author">
            {{ formatCallerName(userInfo.name) }}
          </p>
          <p v-if="!answered" class="call-widget-content-status">
            {{ getCallStatusMessage }}
          </p>
          <p v-else class="call-widget-content-duration">
            {{ formatDuration(callDuration) }}
          </p>
        </div>
        <div
          v-if="callStatus !== 'hangup'"
          class="call-widget-content-actions margin-bottom-1"
        >
          <div
            class="call-widget-content-action mute"
            @click="toggleMuteCall()"
          >
            <fluent-icon
              v-if="muted"
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-mute"
            />
            <fluent-icon
              v-else
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-unmute"
            />
          </div>
          <div class="call-widget-content-action decline" @click="hangupCall()">
            <fluent-icon
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-decline"
            />
            <div v-if="answered" class="padding-left-1 fs-normal">
              {{ $t('INBOX_MGMT.CALL_WIDGET.HANG_UP') }}
            </div>
          </div>
          <div
            v-if="!answered && callStatus === 'incoming'"
            class="call-widget-content-action answer"
            @click="receiveIncomingCall()"
          >
            <fluent-icon
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-answer"
            />
          </div>
        </div>
        <button
          class="call-widget-content-conversation"
          @click="goToConversation()"
        >
          <span>{{ $t('INBOX_MGMT.CALL_WIDGET.GO_TO_CONVERSATION') }}</span>
          <fluent-icon
            size="16"
            view-box="0 0 16 16"
            icon="call-widget-right-arrow"
          />
        </button>
        <div class="call-widget-content-powered">
          <div class="fs-mini">
            {{ $t('INBOX_MGMT.CALL_WIDGET.POWERED_BY') }}
          </div>
          <span class="padding-left-1 fw-bold fs-default">hoory.</span>
        </div>
      </div>
      <div
        class="call-widget-background-image"
        :style="{
          backgroundImage: `linear-gradient(${defaultColor}, ${defaultColor}), ${
            userInfo.imageUrl
              ? `url(${userInfo.imageUrl})`
              : `linear-gradient(${generatedColor}, ${generatedColor})`
          }`,
        }"
      />
    </div>
  </div>
</template>

<script>
import callMixin from 'dashboard/mixins/call';
import { Device } from '@twilio/voice-sdk';
import callApi from 'dashboard/api/call';
import alertMixin from 'shared/mixins/alertMixin';
import routerMixin from 'widget/mixins/routerMixin';
import { mapGetters } from 'vuex';
import ringtone from 'dashboard/assets/audio/ringtone.mp3';

export default {
  mixins: [callMixin, alertMixin, routerMixin],

  data() {
    return {
      isCallWidgetHidden: false,
      isCallWidgetShown: true,
      isCallWidgetHiding: false,
      isSmallWidgetShown: false,
      bottom: 25,
      right: 25,
      isDragging: false,
      answered: false,
      muted: false,
      cursor: 'grab',
      defaultColor: 'rgba(14, 11, 23, 0.8)',
      generatedColor: null,
      token: null,
      device: null,
      registered: false,
      callOutgoing: null,
      callIncoming: null,
      userNumber: '',
      callDurationInterval: null,
      callDuration: 0,
      callStartTime: null,
      ringtone: null,
      callChannelBroadcast: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    getCallStatusMessage() {
      const endDuration = this.formatDuration(this.callDuration);
      switch (this.callStatus) {
        case 'outgoing':
          return this.$t('INBOX_MGMT.CALL_WIDGET.YOU_CALLING');
        case 'hangup':
          return `${this.$t(
            'INBOX_MGMT.CALL_WIDGET.CALL_ENDED'
          )} (${endDuration})`;
        default:
          return this.$t('INBOX_MGMT.CALL_WIDGET.IS_CALLING');
      }
    },
    generateDarkColor() {
      const r = Math.floor(Math.random() * 156);
      const g = Math.floor(Math.random() * 156);
      const b = Math.floor(Math.random() * 156);
      return `rgb(${r}, ${g}, ${b})`;
    },
    activeInbox() {
      const { inbox_id: inboxId } = this.callData;
      return this.$store.getters['inboxes/getInbox'](inboxId);
    },
    agentNumber() {
      return this.activeInbox?.numbers?.[0]?.phone_number || '';
    },
    agentName() {
      const { assignee } = this.callData.meta;
      if (assignee && assignee.name) {
        return assignee.name;
      }
      return 'Unknown';
    },
    userInfo() {
      const { sender } = this.callData.meta;
      let name = 'Unknown';
      let imageUrl = '';
      let phoneNumber = 'Unknown';

      if (sender && sender.name && sender.phone_number) {
        name = sender.name;
        imageUrl = sender.thumbnail;
        phoneNumber = sender.phone_number;
      }

      const callerData = {
        name,
        imageUrl,
        phoneNumber,
      };

      return callerData;
    },
  },
  watch: {
    callStatus(value) {
      if (value === 'hangup') {
        this.destroyRingtone();
        this.destroyDurationInterval();
        clearInterval(this.callDurationInterval);
        this.right = 25;
        this.bottom = 25;
        setTimeout(() => {
          this.isCallWidgetHiding = true;
          this.isCallWidgetShown = false;
          this.isSmallWidgetShown = false;
        }, 3000);
      }
    },
  },
  mounted() {
    this.initializeBroadcastChannel();
    if (this.callStatus === 'incoming') {
      this.playRingtone();
    }
  },
  async created() {
    this.generatedColor = this.generateDarkColor;
    this.userNumber = this.userInfo.phoneNumber;
    await this.startupClient();
    if (this.callStatus === 'outgoing') {
      await this.makeOutgoingCall();
    }
  },
  beforeDestroy() {
    this.destroyRingtone();
    this.destroyDurationInterval();
    this.device.destroy();
    this.callChannelBroadcast.close();
    if (this.callDurationInterval) {
      clearInterval(this.callDurationInterval);
    }
  },
  methods: {
    async startupClient() {
      const response = await callApi.requestToken();
      this.token = response.data.token;
      this.intitializeDevice();
    },

    intitializeDevice() {
      this.device = new Device(this.token, {
        logLevel: 4,
        codecPreferences: ['opus', 'pcmu'],
      });
      this.addDeviceListeners();
      this.device.register();
    },

    addDeviceListeners() {
      this.device.on('registered', () => {
        this.registered = true;
      });
      this.device.on('error', error => {
        this.showAlert('Twilio.Device Error: ' + error.message);
      });
    },

    // Incoming
    async receiveIncomingCall() {
      let params = {
        To: this.userNumber,
        Agent: this.agentNumber,
      };

      if (this.device) {
        const call = await this.device.connect({ params });
        this.answered = true;
        this.callIncoming = call;
        this.destroyRingtone();
        this.sendBroadcastMessage('CALL_ANSWERE_ACTION');
        this.callIncoming.on('accept', this.updateUIAcceptIncomingCall);
        this.callIncoming.on('disconnect', this.updateUIDisconnectIncomingCall);
        this.callIncoming.on('cancel', this.updateUIDisconnectIncomingCall);

        this.callIncoming.on('error', error => {
          this.showAlert('An error has occurred: ' + error);
        });
      } else {
        this.showAlert('Unable to receive call.');
      }
    },

    updateUIAcceptIncomingCall() {
      this.startTrackingDuration();
    },

    updateUIDisconnectIncomingCall() {
      this.hangupCall();
    },

    // Outgoing
    async makeOutgoingCall() {
      let params = {
        To: this.userNumber,
        Agent: this.agentNumber,
        ConversationId: this.callData.id,
        AgentId: this.callData.meta?.assignee?.id,
      };
      if (this.device) {
        const call = await this.device.connect({ params });
        this.callOutgoing = call;
        this.callOutgoing.on('accept', this.updateUIAccepteOutgoingCall);
        this.callOutgoing.on('disconnect', this.updateUIDisconnectOutgoingCall);
        this.callOutgoing.on('cancel', this.updateUIDisconnectOutgoingCall);
        this.callOutgoing.on('error', error => {
          this.showAlert('An error has occurred: ' + error);
        });
      } else {
        this.showAlert('Unable to make call.');
      }
    },

    updateUIAccepteOutgoingCall() {
      this.answered = true;
      this.destroyRingtone();
      this.startTrackingDuration();
    },

    updateUIDisconnectOutgoingCall() {
      this.hangupCall();
    },

    // Broadcast
    initializeBroadcastChannel() {
      this.callChannelBroadcast = new BroadcastChannel('call_channel');
      this.setupBroadcastChannelListener();
    },
    setupBroadcastChannelListener() {
      this.callChannelBroadcast.onmessage = event => {
        if (event.data.type === 'CALL_ANSWERE_ACTION') {
          if (!this.answered) {
            this.hideWidget();
          }
        }
        if (event.data.type === 'MAKE_OUTGOING_CALL') {
          this.setCallStatus('outgoing');
        }
      };
    },
    hideWidget() {
      this.isCallWidgetHidden = true;
      this.isCallWidgetShown = false;
      this.isSmallWidgetShown = false;
      this.destroyRingtone();
      this.destroyDurationInterval();
      this.setCallStatus(null);
      this.setCallData(null);
    },
    sendBroadcastMessage(type) {
      this.callChannelBroadcast.postMessage({ type });
    },

    // General
    hangupCall() {
      this.answered = false;

      this.destroyRingtone();
      this.destroyDurationInterval();
      if (this.callOutgoing) {
        this.callOutgoing.disconnect();
      } else if (this.callIncoming) {
        this.callIncoming.disconnect();
      } else if (this.callStatus === 'incoming') {
        callApi.rejectCall({
          FromNumber: this.userNumber,
          AgentName: this.agentName,
        });
      }
      this.setCallStatus('hangup');
      setTimeout(() => {
        this.setCallStatus(null);
        this.setCallData(null);
      }, 3400);
    },

    startTrackingDuration() {
      this.callStartTime = Date.now();
      this.callDurationInterval = setInterval(() => {
        this.callDuration = Math.floor(
          (Date.now() - this.callStartTime) / 1000
        );
      }, 1000);
    },

    toggleChat() {
      if (this.isCallWidgetHidden) {
        this.isCallWidgetHidden = false;
        this.isCallWidgetShown = true;
        this.isSmallWidgetShown = false;
      } else if (!this.isCallWidgetHiding) {
        this.isCallWidgetHiding = true;
        this.isCallWidgetShown = false;
        this.isSmallWidgetShown = true;
      } else {
        this.isCallWidgetShown = true;
        this.isCallWidgetHiding = false;
        this.isSmallWidgetShown = false;
      }
    },

    playRingtone() {
      if (!this.ringtone) {
        this.ringtone = new Audio(ringtone);
        this.ringtone.loop = true;
      }
      this.ringtone.play().catch(error => {
        this.showAlert('Error playing ringtone:', error);
      });
    },

    destroyRingtone() {
      if (this.ringtone) {
        this.ringtone.pause();
        this.ringtone.src = '';
        this.ringtone = null;
      }
    },

    destroyDurationInterval() {
      if (this.callDurationInterval) {
        clearInterval(this.callDurationInterval);
        this.callDurationInterval = null;
      }
    },

    toggleMuteCall() {
      const isMuted = this.muted;
      if (this.ringtone) {
        this.ringtone.volume = isMuted ? 1 : 0;
      }
      [this.callOutgoing, this.callIncoming].forEach(call => {
        if (call) {
          call.mute(!isMuted);
        }
      });
      this.muted = !isMuted;
    },

    goToConversation() {
      if (
        !this.$route.params.conversation_id ||
        Number(this.$route.params.conversation_id) !== this.callData.id
      ) {
        this.$router.push(
          `/app/accounts/${this.accountId}/conversations/${this.callData.id}`
        );
      }
    },

    mouseDown() {
      this.isDragging = true;
      this.cursor = 'grabbing';
      document.addEventListener('mousemove', this.mouseMove);
      document.addEventListener('mouseup', this.mouseUp);
      document.addEventListener('selectstart', this.preventTextSelection);
    },

    mouseMove(event) {
      if (this.isDragging) {
        this.right = window.innerWidth - event.clientX - 46;
        this.bottom = window.innerHeight - event.clientY - 15;
      }
    },

    mouseUp() {
      this.isDragging = false;
      this.cursor = 'grab';
      document.removeEventListener('mousemove', this.mouseMove);
      document.removeEventListener('mouseup', this.mouseUp);
      document.removeEventListener('selectstart', this.preventTextSelection);
    },

    preventTextSelection(event) {
      event.preventDefault();
    },
  },
};
</script>
