import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      callData: 'call/getCallData',
      callStatus: 'call/getCallStatus',
      currentUser: 'getCurrentUser',
      missedCalls: 'call/getMissedCalls',
    }),
    showCallWidget() {
      if (this.callData?.meta?.assignee?.id === this.currentUser.id) {
        return ['outgoing', 'incoming', 'hangup'].includes(this.callStatus);
      }
      return false;
    },
  },
  methods: {
    setCallStatus(status) {
      this.$store.dispatch('call/setCallStatus', status);
    },
    setCallData(data) {
      this.$store.dispatch('call/setCallData', data);
    },
    setMissedCalls(calls) {
      this.$store.dispatch('call/setMissedCalls', calls);
    },
    userInitial(username) {
      const parts = username.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },
    formatCallerName(name) {
      return name
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    formatDuration(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let formatted = '';

      if (hrs > 0) {
        formatted += `${hrs}:${mins < 10 ? '0' : ''}`;
      }

      formatted += `${mins}:${secs < 10 ? '0' : ''}${secs}`;
      return formatted;
    },
  },
};
