<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div v-if="isLoading" class="call-based-spinner spinner-cover">
      <spinner-hoory size="normal" color="#fff" />
    </div>
    <div v-if="!isComplete" class="column call-based-popup">
      <section v-if="!hasDefaultPaymentMethod">
        <div class="main-content text-center">
          <div class="margin-top-3">
            <img :src="addCardSvg" />
          </div>
          <h6 class="margin-top-3 fs-medium fw-bold">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.ADD_CARD_TITLE') }}
          </h6>
          <p class="fs-small">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.ADD_CARD_DESC') }}
          </p>
        </div>
        <div class="call-based-popup-actions">
          <woot-button
            size="medium"
            variant="flat"
            class="full"
            color-scheme="primary"
            @click.prevent="execActivateSubscription()"
          >
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.ADD_CARD') }}
          </woot-button>
        </div>
      </section>
      <section v-else>
        <woot-modal-header
          :header-title="$t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.TITLE')"
        />
        <div class="main-content">
          <div class="main-message margin-bottom-2">
            <div class="margin-right-2">
              <img :src="infoSvg" />
            </div>
            <div class="main-message-content">
              <p class="secondary-text margin-bottom-1 fs-normal">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.SELECTED_NUMBER') }}
              </p>
              <div class="main-message-details">
                <h6 class="fw-bold">{{ selectedNumber.phone_number }}</h6>
                <p
                  v-if="isBillingDataLoaded"
                  class="color-primary fs-small fw-medium"
                >
                  {{ formattedPrice(selectedNumber.fee * 100) }}/
                  {{ isMonthlyText }}
                </p>
              </div>
            </div>
          </div>
          <p class="fs-small">
            {{
              $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.DESCRIPTION', {
                fee: formattedPrice(selectedNumber.fee * 100),
              })
            }}
          </p>
          <div class="usage">
            <h6 class="fs-normal fw-medium">
              {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.USAGE_PRICING') }}
            </h6>
            <div class="usage-item">
              <div class="usage-item-icon">
                <fluent-icon icon="megaphone" size="22" />
              </div>
              <div class="main-message-content">
                <h6 class="margin-bottom-1 usage-header">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.VOICE'
                    )
                  }}
                </h6>
                <p class="margin-bottom-1">
                  {{
                    formattedPrice(numberPricing ? numberPricing.voice.make : 6)
                  }}
                  {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_MINUTE') }}
                  ({{
                    $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.OUTBOUND_CALLS')
                  }})
                </p>
                <p>
                  {{
                    formattedPrice(
                      numberPricing ? numberPricing.voice.receive : 4
                    )
                  }}
                  {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_MINUTE') }}
                  ({{
                    $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.INBOUND_CALLS')
                  }})
                </p>
              </div>
            </div>
            <div class="usage-item margin-bottom-2">
              <div class="usage-item-icon">
                <fluent-icon icon="brand-sms" size="22" />
              </div>
              <div class="main-message-content">
                <h6 class="margin-bottom-1 usage-header">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.SMS'
                    )
                  }}
                </h6>
                <p class="margin-bottom-1">
                  {{
                    formattedPrice(numberPricing ? numberPricing.sms.out : 3)
                  }}
                  {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_SMS') }}
                  ({{
                    $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.OUTBOUND_SMS')
                  }})
                </p>
                <p>
                  {{ formattedPrice(numberPricing ? numberPricing.sms.in : 3) }}
                  {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_SMS') }}
                  ({{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.INBOUND_SMS') }})
                </p>
              </div>
            </div>
          </div>
          <h6 class="fs-normal fw-medium">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.REGISTRATION_TITLE') }}
          </h6>
          <p>
            {{
              $t(
                'INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.REGISTRATION_DESCRIPTION'
              )
            }}
          </p>
          <div class="main-message margin-top-2">
            <div class="main-message-content">
              <p>
                {{
                  $t(
                    'INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.REGISTRATION_TOOLTIP'
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="call-based-popup-actions">
          <woot-button
            size="medium"
            variant="flat"
            class="full"
            color-scheme="primary"
            @click.prevent="execProvisionNumber()"
          >
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.BUY_NUMBER') }}
          </woot-button>
        </div>
      </section>
    </div>
    <div v-else class="column call-based-popup">
      <section>
        <div class="main-content text-center">
          <div class="margin-top-3">
            <img :src="completedSvg" />
          </div>
          <h6 class="margin-top-3 fs-medium fw-bold">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.COMPLETED') }}
          </h6>
          <p class="fs-small">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.SUCCESS_MSG') }}
          </p>
          <div class="main-message margin-top-2">
            <div class="main-message-content">
              <p class="margin-bottom-1 fs-normal">
                {{
                  $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PURCHASED_NUMBER')
                }}
              </p>
              <h6 class="fw-bold fs-medium margin-bottom-0">
                {{ selectedNumber.phone_number }}
              </h6>
            </div>
          </div>
        </div>
        <div class="call-based-popup-actions">
          <woot-button
            size="medium"
            variant="flat"
            class="full"
            color-scheme="primary"
            @click="onClose"
          >
            {{
              inboxActionMode === 'edit_call'
                ? $t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.BUTTON')
                : $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.ADD_AGENTS')
            }}
          </woot-button>
        </div>
      </section>
    </div>
  </woot-modal>
</template>

<script>
import inboxMixin from 'shared/mixins/inboxMixin';
import billingMixin from 'dashboard/mixins/billing';
import accountMixin from 'dashboard/mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';
import addCardSvg from 'dashboard/assets/icons/call/addCard.svg';
import completedSvg from 'dashboard/assets/icons/call/completed.svg';
import infoSvg from 'dashboard/assets/icons/call/info.svg';

export default {
  components: {
    SpinnerHoory,
  },
  mixins: [inboxMixin, billingMixin, accountMixin, alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      infoSvg,
      addCardSvg,
      completedSvg,
      show: true,
      isLoading: false,
      isComplete: false,
    };
  },
  created() {
    this.fetchNumberPricing({ accountId: this.accountId });
  },
  beforeDestroy() {
    if (!this.isComplete) return;
    if (this.inboxActionMode === 'edit_call') {
      this.setInboxActionMode(null);
      this.gotToSettings();
    } else {
      this.goToGivenStep('settings_inboxes_add_agents');
    }
  },
  methods: {
    removePlusSign(str) {
      if (str.charAt(0) === '+') {
        return str.slice(1);
      }
      return str;
    },
    execActivateSubscription() {
      this.activateSubscription({
        accountId: this.accountId,
        returnUrl: `${window.location.href}?redirected=true`,
      });
    },
    async execProvisionNumber() {
      this.isLoading = true;
      try {
        const data = {
          inboxId: this.$route.params.inbox_id,
          accountId: this.accountId,
          countryCode: this.selectedNumber.iso_country,
          numberType: this.selectedNumber.type,
          number: this.removePlusSign(this.selectedNumber.phone_number),
        };
        await this.provisionNumber(data);
        this.isComplete = true;
      } catch (error) {
        this.showAlert(`Error: ${error?.message}`);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.usage {
  margin: var(--space-medium) 0;
  .usage-item {
    display: flex;
    justify-content: start;
    padding: var(--space-normal) 0;
    .usage-item-icon {
      margin-right: var(--space-normal);
      font-size: var(--font-size-big);
    }
  }
  .usage-header {
    font-size: var(--font-size-normal);
    border-bottom: 1px solid var(--s-100);
  }
}
</style>
