import * as types from '../mutation-types';

export const state = {
  callData: null,
  callStatus: null,
  missedCalls: [
    { id: 1, name: 'Karen Margaryan' },
    { id: 2, name: 'Aram Avagumyan' },
  ],
};

export const getters = {
  getCallData($state) {
    return $state.callData;
  },
  getMissedCalls($state) {
    return $state.missedCalls;
  },
  getCallStatus($state) {
    return $state.callStatus;
  },
};

export const actions = {
  setCallStatus({ commit }, status) {
    commit(types.default.SET_CALL_STATUS, status);
  },
  setMissedCalls({ commit }, calls) {
    commit(types.default.SET_MISSED_CALLS, calls);
  },
  setCallData({ commit }, data) {
    commit(types.default.SET_CALL_DATA, data);
  },
  newCall: ({ commit, rootState, dispatch }, data) => {
    if (
      ['outgoing', 'incoming', 'hangup'].includes(state.callStatus) ||
      !data.meta.assignee
    ) {
      dispatch('queueNextCall', data);
    } else if (rootState.auth?.currentUser.id === data?.meta?.assignee?.id) {
      commit(types.default.SET_CALL_DATA, data);
      commit(types.default.SET_CALL_STATUS, 'incoming');
    }
  },
  nextCall: ({ commit, rootState }, data) => {
    setTimeout(() => {
      let queueData = JSON.parse(localStorage.getItem('queuedCalls')) || [];
      if (
        queueData.includes(data.id) &&
        rootState.auth?.currentUser.id === data?.meta?.assignee?.id
      ) {
        commit(types.default.SET_CALL_DATA, data);
        commit(types.default.SET_CALL_STATUS, 'incoming');
      }
      queueData = queueData.filter(id => id !== data.id);
      localStorage.setItem('queuedCalls', JSON.stringify(queueData));
    }, 3400);
  },
  endCall: ({ commit }, data) => {
    let queueData = JSON.parse(localStorage.getItem('queuedCalls')) || [];
    if (!queueData.includes(data.id) && data.id === state.callData?.id) {
      commit(types.default.SET_CALL_STATUS, 'hangup');
      setTimeout(() => {
        commit(types.default.SET_CALL_DATA, null);
        commit(types.default.SET_CALL_STATUS, null);
      }, 3400);
    }
    queueData = queueData.filter(id => id !== data.id);
    localStorage.setItem('queuedCalls', JSON.stringify(queueData));
  },
  queueNextCall: (_, data) => {
    const queueData = JSON.parse(localStorage.getItem('queuedCalls')) || [];
    queueData.push(data.id);
    localStorage.setItem('queuedCalls', JSON.stringify(queueData));
  },
  updateOutgoingCall: ({ commit }, data) => {
    if (state.callData && state.callStatus === 'outgoing') {
      commit(types.default.SET_CALL_DATA, data);
    }
  },
};

export const mutations = {
  [types.default.SET_CALL_DATA]($state, data) {
    $state.callData = data;
  },
  [types.default.SET_CALL_STATUS]($state, status) {
    $state.callStatus = status;
  },
  [types.default.SET_MISSED_CALLS]($state, calls) {
    $state.missedCalls = calls;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
